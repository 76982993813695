import React, { Component } from "react"
import Slider from "react-slick"
import PropTypes from "prop-types"
import { shuffleArray, getMonthYearFromStrings } from "../../utils/utils"
import ImageMeta from "../ImageMeta"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, StaticQuery, graphql } from "gatsby"

import { SamplePrevArrow, SampleNextArrow } from "./ChevronArrows"

function SingleSlide(props) {
  return (
    <div>
      <Link
        className="carousel-image-link"
        to={"/" + props.title.toLowerCase()}>
        <ImageMeta
          cloudName="nuvolum"
          publicId={props.thumbnailPublicId}
          width="375"
          height="600">
          <Transformation crop="scale" height="419" />
        </ImageMeta>
        <div className="carousel-caption-wrapper">
          <span className="carousel-name">
            {"Meet " + props.reviewerName + " "}
          </span>
          <span className="carousel-time">
            {getMonthYearFromStrings(
              props.monthYear.year,
              props.monthYear.month
            )}
          </span>
        </div>
      </Link>
    </div>
  )
}

SingleSlide.propTypes = {
  title: PropTypes.string,
  thumbnailPublicId: PropTypes.string,
  reviewerName: PropTypes.string
}

class ReviewCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      relatedReviews: props.relatedReviews,
      slideID: props.id
    }
  }

  // Shuffle on mount, shuffling in render causes multiple shuffles
  componentDidMount() {
    if (this.props.noShuffle === false) {
      let relatedReviews = this.state.relatedReviews
      shuffleArray(relatedReviews)
      this.setState({
        relatedReviews: relatedReviews
      })
    }
  }

  render() {
    const settings = {
      className: "desktop-slider-375x600",
      accessibility: true,
      adaptiveHeight: true,
      infinite: this.props.relatedReviews.length <= 3 ? false : true,
      centerPadding: "0px",
      slidesToShow: 5,
      swipeToSlide: true,
      focusOnSelect: false,
      draggable: false,
      rows: 1,
      speed: 500,
      nextArrow: <SampleNextArrow targetID={this.state.slideID} />,
      prevArrow: <SamplePrevArrow targetID={this.state.slideID} />,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1125,
          settings: {
            slidesToShow: this.props.relatedReviews.length < 3 ? 2 : 3,
            infinite: true,
            className:
              this.props.relatedReviews.length < 3
                ? "desktop-slider-375x600 slide-count-2"
                : "desktop-slider-375x600"
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 2,
            infinite: true
          }
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 1,
            infinite: true
          }
        }
      ]
    }

    const sliderStyle = {
      width: "auto",
      height: "auto",
      margin: "0px auto"
    }

    const sliderCopy = {
      carouselName: "Hear From Our Patients",
      carouselTime: "December 2019",
      carouselLanguage: "ENG",
      carouselTypeTextTitle: "Patients"
    }

    var slides = this.state.relatedReviews.map(review => {
      return (
        <SingleSlide
          key={review.title}
          title={review.title}
          reviewerName={review.reviewerName}
          thumbnailPublicId={review.thumbnailPublicId}
          monthYear={review.monthYear}
          reviewLanguage={review.reviewLanguage}
        />
      )
    })

    return (
      <div id={this.state.slideID}>
        {this.state.relatedReviews.length > 0 && (
          <Slider {...settings} style={{ ...sliderStyle }}>
            {slides}
          </Slider>
        )}
      </div>
    )
  }
}

ReviewCarousel.propTypes = {
  relatedReviews: PropTypes.array
}

export default ReviewCarousel
